<template>
  <div class="filter-item" v-click-outside="hide">
    <label class="field-label">{{ title }}</label>
    <div class="select" :class="{'active' :  isOpened}">
      <div class="select-button" @click="toggle">
        <input
            type="text"
            class="select-value"
            :value="selectedOption.title"
            placeholder="Сортировать"
            readonly
        >
        <div class="select-arrow"></div>
      </div>
      <transition name="fade">
        <div v-if="isOpened" class="select-dropdown">
          <div
              class="select-option"
              v-for="(option, index) in options"
              :key="index"
              :class="{'active' : (option.value === selectedOption.value)}"
              @click="select(option)"
          >
            {{ option.title }}
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'SingleSelect',
  props: {
    title: String,
    options: Array,
  },

  data() {
    return {
      isOpened: false,
      selectedOption: this.options[0],
    };
  },

  mounted() {},

  watch: {},

  methods: {
    toggle() {
      !this.isOpened ? this.show() : this.hide();
    },

    show() {
      this.isOpened = true;
    },

    hide() {
      this.isOpened = false;
    },

    select(option) {
      this.selectedOption = option;
      this.$emit('input', this.selectedOption.value);
      this.hide();
    },
  },

  directives: {
    ClickOutside,
  },

  components: {},
};
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.select-dropdown {
  display: block;
}
</style>
