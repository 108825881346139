const sortArrayByValue = (array, value) => {
    return array.sort((a, b) => {
        if (a[value] > b[value]) {
            return 1;
        }
        if (a[value] < b[value]) {
            return -1;
        }
        return 0;
    });
};

const reverseSortByValue = (array, value) => {
    return array.sort((a, b) => {
        if (a[value] < b[value]) {
            return 1;
        }
        if (a[value] > b[value]) {
            return -1;
        }
        return 0;
    });
};

const sortByDate = (array) => {
    return array.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
    });
};

const reverseSortByDate = (array) => {
    return array.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
    });
};

export const sortArray = (array, {by, order = 'asc', dataType = 'string',}) => {
    if (dataType !== 'string' && dataType !== 'date') {
        throw 'Invalid data type';
    }
    if (order !== 'asc' && order !== 'desc') {
        throw 'Invalid data type';
    }
    if (dataType === 'date') {
        if (order === 'asc') {
            return sortByDate(array);
        }
        if (order === 'desc') {
            return reverseSortByDate(array);
        }
    }
    if (dataType === 'string') {
        if (order === 'asc') {
            return sortArrayByValue(array, by);
        }
        if (order === 'desc') {
            return reverseSortByValue(array, by);
        }
    }
};

