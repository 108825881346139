import HTTP from './default';

export default {
    getCourses({
                   search = null,
                   ordering = null,
                   tag = null,
                   themes = null,
                   is_completed = null,
               }) {
        return HTTP({
            url: '/user/courses/',
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('userToken')}`,
            },
            params: {
                search,
                ordering,
                tag,
                themes,
                is_completed,
            },
        });
    },

    getProgress() {
        return HTTP({
            url: '/user/progress/',
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('userToken')}`,
            },
        });
    },

    getSurveys(params) {
        return HTTP({
            url: '/user/survey/',
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('userToken')}`,
            },
            params: params,
        });
    },

    getAllPrograms(params) {
        return HTTP({
            url: '/user/programs/',
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('userToken')}`,
            },
            params: {
                ...params,
                page_size: 500,
            },
        });
    },
};
