<template>
  <div v-click-outside="closeSearchInput">
    <div :class="{'search-hide': isOpenedSearch}" @click="showSearchInput" class="search-toggle">
      <div class="icon-item">
        <img src="@/assets/img/icons/icon-search.svg" alt="">
        <img src="@/assets/img/icons/icon-search-color.svg" alt="">
      </div>
    </div>
    <div class="search-block">
      <form @submit.prevent>
        <button class="search-button">
          <div class="icon-item">
            <img src="@/assets/img/icons/icon-search.svg" alt="">
            <img src="@/assets/img/icons/icon-search-color.svg" alt="">
          </div>
        </button>
        <input v-model="searchValue"
               type="text"
               class="field-value"
               placeholder="Поиск по курсам">
        <div class="search-clear" @click="clearSearchInput">
          <img src="@/assets/img/icons/icon-remove.svg" alt="">
          Очистить
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'Search',
  props: ['value',],

  data() {
    return {
      isOpenedSearch: false,
      searchValue: this.value,
    };
  },

  mounted() {
  },

  watch: {
    searchValue(value) {
      this.$emit('input', value);
    },
  },

  methods: {
    showSearchInput() {
      this.isOpenedSearch = true;
      this.$emit('show', this.isOpenedSearch);
    },

    closeSearchInput() {
      if (this.isOpenedSearch) {
        this.isOpenedSearch = false;
        this.$emit('show', this.isOpenedSearch);
      }
    },

    clearSearchInput() {
      this.searchValue = '';
    },
  },

  directives: {
    ClickOutside,
  },

  components: {},
};
</script>

<style scoped>
.search-hide {
  display: none;
}
</style>
