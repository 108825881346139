<template>
  <div class="faq-item toggle-block md"
       :class="{'active': isOpened}">
    <div class="faq-title toggle-button icon-hover"
         @click="isOpened = !isOpened">
      <div class="icon-item">
        <img src="@/assets/img/icons/icon-program.svg" alt="">
        <img src="@/assets/img/icons/icon-program-hover.svg" alt="">
      </div>
      <div class="faq-subtitle">
        Программа:
        <span>
          <router-link :to="`/program/${program.id}`">{{ program.name }}</router-link>
        </span>
      </div>
    </div>
    <VueSlideToggle :open="isOpened">
      <div class="toggle-content" style="display: block;">
        <div class="faq-content">
          <div class="progress-wrap" :class="{'success': program.progress_percent === 100}">
            <div class="progress-title">Общий прогресс: {{ program.progress_percent }} %</div>
            <div class="progress-item">
              <div class="progress-line" :style="{width: program.progress_percent + '%'}"></div>
            </div>
          </div>
          <br>
          <h4 class="simple-title">
            Состав программы
            <span v-if="program.navigation === 'free'">
               <img src="@/assets/img/icons/icon-program-free-5.svg" alt="">
               свободное прохождение
            </span>
            <span v-else>
               <img src="@/assets/img/icons/icon-program-order.svg" alt="">
               последовательное прохождение
            </span>
          </h4>
          <div class="program-stripe-group">
            <div v-for="(course, index) in program.courses"
                 :key="course.id"
                 class="program-stripe-item"
                 :class="{'ready': course.is_completed, 'disabled': course.is_blocked}">
              <div class="program-stripe-box">
                <div class="program-stripe-index">{{ index + 1 }}.</div>
                <div class="program-stripe-pic"
                     :style="{'background-image': 'url(' + course.image + ')'}">
                </div>
                <div class="program-stripe-content">
                  <div class="program-stripe-header">
                    <div class="program-stripe-date">
                      {{ format(new Date(course.date_create), 'd MMMM', {locale: ru}) }}
                    </div>
                  </div>
                  <router-link :to="`/user/course/${course.slug}-${course.id}`"
                               class="program-stripe-title">
                    {{ course.name }}
                  </router-link>

              <div class="program-stripe-info-group">
                  <div class="marker-item hollow" v-if="course.tag">{{ course.tag }}</div>
              </div>
                </div>
              </div>
              <div v-if="course.is_completed" class="program-stripe-box">
                <div class="program-stripe-status green">
                  <img src="@/assets/img/icons/icon-program-check.svg" alt="">
                  Пройден
                </div>
              </div>
              <div v-else-if="course.is_blocked" class="program-stripe-box">
                <div class="program-stripe-status gray">
                  <img src="@/assets/img/icons/icon-program-locked.svg" alt="">
                  Недоступен
                </div>
              </div>
              <div v-else class="program-stripe-box">
                <router-link :to="`/user/course/${course.slug}-${course.id}`"
                             class="button-color">
                  К обучению
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </VueSlideToggle>
  </div>
</template>

<script>
import {format} from 'date-fns';
import ru from 'date-fns/locale/ru';
import {VueSlideToggle} from 'vue-slide-toggle';

export default {
  name: 'ProgramSlide',

  props: {
    program: Object,
  },

  components: {
    VueSlideToggle,
  },

  data() {
    return {
      isOpened: false,
      ru,
    };
  },

  methods: {
    format,
  },
};
</script>

<style scoped>

</style>
